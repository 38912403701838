import _firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-functions";
import "firebase/storage";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyARY8sfxM9qUBU-IWYjBshPe1jpyUvhZzo",
  authDomain: "hive-office-jonahgroup.firebaseapp.com",
  databaseURL: "https://hive-office-jonahgroup.firebaseio.com",
  projectId: "hive-office-jonahgroup",
  storageBucket: "hive-office-jonahgroup.appspot.com",
  messagingSenderId: "690193778082",
  appId: "1:690193778082:web:9780cb8e71ac19972a5efb",
  measurementId: "G-K28YX5JVXX"
};
// Initialize Firebase
_firebase.initializeApp(firebaseConfig);

const _storage = _firebase.storage();
// Export auth and firestore references
export const firebase = _firebase;
export const storage = _storage;
export const auth = _firebase.auth();
export const database = _firebase.firestore();

export const hiveIamUrl = "https://hiveidentity-ca.web.app";
export const hiveSyncUrl = "https://hivesync-ca.web.app";
export const hiveApiUrl = "https://hiveapi-ca.web.app";
export const hiveAppointmentUrl = "https://hive-appointment-ca.web.app"
export const ssoUrl = `${hiveIamUrl}/oauth/login?source=hive-admin-web&company=`;
